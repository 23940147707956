import { IonContent, IonPage } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import PageToolbar from '../components/PageToolbar';

const HomePage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <PageToolbar title="Pass2Go" />
        <ExploreContainer />
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
