import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import PageToolbar from '../components/PageToolbar';

import all_people from '../components/PeopleDB';

interface ContactDetailsPageProps extends RouteComponentProps<{
    user: string;
  }> {}

const ContactDetailsPage: React.FC<ContactDetailsPageProps> = ({ match }) => {
  var person = all_people[parseInt(match.params.user)]
  return (
    <IonPage>
      <PageToolbar title="Contact details" back/>

      <IonContent fullscreen>
        <IonCard>
            <img src="https://ionicframework.com/docs/demos/api/card/madison.jpg" alt=""/>
            <IonCardHeader>
              <IonCardTitle>{person.name}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              Founded in 1829 on an isthmus between Lake Monona and Lake Mendota, Madison was named the capital of the
              Wisconsin Territory in 1836.
            </IonCardContent>
          </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ContactDetailsPage;
