import { IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';

import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4S2L6PRMlzk4lL07rEw6ox9fJYz_cr28",
  authDomain: "pass2go-web.firebaseapp.com",
  projectId: "pass2go-web",
  storageBucket: "pass2go-web.appspot.com",
  messagingSenderId: "518682020866",
  appId: "1:518682020866:web:7e0a11adbc40e1fc586b1b"
}
firebase.initializeApp(firebaseConfig)
  
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/login',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

console.log("firebase", firebase);
console.log("firebase.auth", firebase.auth);


const LoginPage: React.FC<RouteComponentProps> = ({ match }) => {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (isSignedIn) {
    console.log(firebase.auth().currentUser);
    firebase.auth().currentUser!.getIdToken(true).then( value => {
      console.log("Token:", value);
    } )
    return (
      <IonPage id="login">
        <p>Welcome {firebase.auth().currentUser!.displayName}! You are now signed-in!</p>
        <p><a onClick={() => firebase.auth().signOut()}>Sign-out</a></p>
      </IonPage>
    );
  } else {
    return (
      <IonPage id="login">
        Faça login aqui
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </IonPage>
    );
  }
};

export default LoginPage;
