interface Person {
  name: string;
  cpf: string;
}

const all_people: Person[] = [
  {name: "Ana Beatriz Moura Gaal", cpf: "405.029.130-12"},
  {name: "Ana Beatriz Onelli Abe", cpf: "428.339.230-45"},
  {name: "Ana Beatriz Pereira de Araujo", cpf: "429.826.460-18"},
  {name: "Ana Beatriz Porto Fernandes da Si...", cpf: "434.136.740-13"},
  {name: "Ana Beatriz Silva Bicho", cpf: "441.984.230-44"},
  {name: "Ana Beatriz Soardi Arakaki", cpf: "489.369.780-31"},
  {name: "Ana Beatriz Sugahara Ribeiro", cpf: "508.274.215-39"},
  {name: "Ana Beatriz Theodoro Santana", cpf: "506.367.790-52"},
  {name: "Ana Beatriz Waldvogel Oliveira", cpf: "528.941.720-09"},
  {name: "Ana Carolina Abreu de Freitas", cpf: "525.245.750-16"},
  {name: "Ana Carolina Aguiar Rocha", cpf: "419.719.500-26"},
  {name: "Ana Carolina Alkimim Fenoglio", cpf: "455.195.445-13"},
  {name: "Ana Carolina Altemari", cpf: "513.021.850-66"},
  {name: "Ana Carolina Alves Sousa", cpf: "507.139.720-09"},
  {name: "Ana Carolina Amado Britto", cpf: "191.802.160-18"},
  {name: "Ana Carolina Costa Chaves", cpf: "530.743.165-23"},
  {name: "Ana Carolina de Oliveira Rodrigue...", cpf: "159.072.790-44"},
  {name: "Ana Carolina de Oliveira Schroede...", cpf: "511.102.550-39"},
  {name: "Ana Carolina dos Reis Amora Ram...", cpf: "127.407.145-15"},
  {name: "Ana Carolina dos Santos Agassi", cpf: "471.475.540-37"},
  {name: "Ana Carolina dos Santos Lebre", cpf: "376.084.230-45"},
  {name: "Ana Carolina Duarte", cpf: "475.582.535-35"},
  {name: "Ana Carolina Fernandes de Souza", cpf: "054.975.780-29"},
  {name: "Ana Carolina Ferreira de Figueired...", cpf: "129.264.710-05"},
  {name: "Ana Carolina Francisco", cpf: "448.796.160-18"},
  {name: "Ana Carolina Galdino Picolli", cpf: "419.884.460-17"},
  {name: "Ana Carolina Garcia Costa", cpf: "054.787.855-67"},
  {name: "Ana Carolina Goncalves Lauriano ...", cpf: "468.079.165-22"},
  {name: "Ana Carolina Lima Salerno Ramos", cpf: "515.594.105-07"},
  {name: "Ana Carolina Lobo Furlan", cpf: "489.030.855-68"},
  {name: "Ana Carolina Magalhaes Justo", cpf: "477.454.570-43"},
  {name: "Ana Carolina Maia Gouveia", cpf: "161.860.500-26"},
  {name: "Ana Carolina Maria de Paiva", cpf: "419.127.770-22"},
  {name: "Ana Carolina Merss dos Santos", cpf: "385.703.255-59"},
  {name: "Ana Carolina Ormond Tacano", cpf: "455.093.740-14"},
  {name: "Ana Carolina Pantoja de Faria", cpf: "136.856.510-29"},
  {name: "Ana Carolina Pinto Roim", cpf: "467.916.255-58"},
  {name: "Ana Carolina Rodrigues Borges", cpf: "455.821.240-47"},
  {name: "Ana Carolina Salvador da Silva", cpf: "419.720.160-18"},
  {name: "Ana Carolina Santana Manfrinato ...", cpf: "516.747.165-25"},
  {name: "Ana Carolina Santos Paul", cpf: "702.868.270-64"},
  {name: "Ana Carolina Schneider", cpf: "010.054.230-44"},
  {name: "Ana Carolina Sousa Campos Marti...", cpf: "463.983.405-04"},
  {name: "Ana Carolina Sueyasu", cpf: "462.721.285-68"},
  {name: "Ana Carolina Zanquetta de Oliveir...", cpf: "446.121.780-31"},
  {name: "Ana Caroline dos Santos", cpf: "497.699.165-25"},
  {name: "Ana Cecilia Amaral Araujo", cpf: "129.572.545-38"},
  {name: "Ana Clara Alves Machado Pimenta", cpf: "148.563.105-07"},
  {name: "Ana Clara Alves Pacifico Costa", cpf: "438.108.740-14"},
  {name: "Ana Clara Canas Xavier", cpf: "532.385.165-24"},
  {name: "Ana Clara Costa Machado", cpf: "024.237.235-46"},
  {name: "Ana Clara da Cunha Fleming", cpf: "014.476.265-61"},
  {name: "Ana Clara da Silva Alves", cpf: "414.415.520-31"},
  {name: "Ana Clara de Aguiar Silva", cpf: "392.316.100-01"},
  {name: "Ana Clara de Azevedo Motta", cpf: "050.048.740-14"},
  {name: "Ana Clara de Oliveira Lima", cpf: "491.140.775-27"},
  {name: "Ana Clara de Sao Jose", cpf: "444.569.771-23"},
  {name: "Ana Clara Delben Gianella", cpf: "470.416.440-12"},
  {name: "Ana Clara Duca Trujillo", cpf: "428.051.165-22"},
  {name: "Ana Clara Ferreira Parreira", cpf: "106.804.720-09"},
  {name: "Ana Clara Gelonezi", cpf: "467.928.795-55"},
  {name: "Ana Clara Germano Tostes", cpf: "415.893.505-28"},
  {name: "Ana Clara Gimenez", cpf: "456.016.145-15"},
  {name: "Ana Clara Kozlowski Caravieri", cpf: "140.257.771-23"},
  {name: "Ana Clara Machado Molinari", cpf: "437.454.435-10"},
  {name: "Ana Clara Magalhaes Franzoni", cpf: "381.884.475-21"},
  {name: "Ana Clara Midena", cpf: "369.644.501-27"},
  {name: "Ana Clara Oliveira Souza", cpf: "471.463.225-43"},
  {name: "Ana Clara Sabino Marinho de Arau...", cpf: "109.282.230-44"},
  {name: "Ana Clara Sampaio Pires", cpf: "528.291.105-07"},
  {name: "Ana Clara Silvares Batista", cpf: "508.269.225-43"},
  {name: "Ana Clara Simon", cpf: "459.577.125-10"},
  {name: "Ana Clara Valla Alarcon", cpf: "524.608.445-13"},
  {name: "Ana Clara Vilas Boas de Castro", cpf: "179.840.435-10"},
  {name: "Ana Clara Zanardi Teixeira", cpf: "528.315.230-45"},
  {name: "Ana Clara Zanchet Bordignon", cpf: "060.783.450-15"},
  {name: "Ana Claudia da Silva Reis", cpf: "367.688.140-13"},
  {name: "Ana de Queiroz Avila", cpf: "444.384.115-09"},
  {name: "Ana Elisa de Assis Gomes", cpf: "027.723.160-20"},
  {name: "Ana Elisa Domingues Andrade", cpf: "428.497.495-25"},
  {name: "Ana Elisa Leonel Ferreira", cpf: "189.727.775-25"},
  {name: "Ana Elisa Novaes Vidal Martins", cpf: "395.212.425-08"},
  {name: "Ana Elisa Paulino Bollini", cpf: "526.189.101-06"},
  {name: "Ana Elisa Ribeiro Furtado", cpf: "100.229.735-12"},
  {name: "Ana Fernandes Lima", cpf: "037.845.150-16"},
  {name: "Ana Flavia D’Agostino de Oliveira ...", cpf: "483.171.240-47"},
  {name: "Ana Flavia de Almeida Marcacini", cpf: "063.946.280-67"},
  {name: "Ana Gabriela da Silveira Fritz", cpf: "521.418.110-08"},
  {name: "Ana Gabriela de Oliveira Monteiro", cpf: "121.953.230-45"},
  {name: "Ana Gabriela Dourado Teixeira de ...", cpf: "018.622.240-47"},
  {name: "Ana Gabriela Enes Rocha", cpf: "498.824.825-61"},
  {name: "Ana Isabel Seccacci Resch", cpf: "521.704.160-18"},
  {name: "Ana Isabel Silva Menezes", cpf: "361.625.160-19"},
  {name: "Ana Julia da Mata", cpf: "377.460.771-23"},
  {name: "Ana Julia da Silva Maekawa", cpf: "501.232.520-31"},
  {name: "Ana Julia de Magalhaes Pina", cpf: "498.822.501-27"},
  {name: "Ana Julia de Melo Gonzalez", cpf: "230.459.230-45"},
  {name: "Ana Julia de Moraes Silva", cpf: "459.165.450-15"},
  {name: "Ana Julia de Oliveira Costa", cpf: "449.972.260-60"},
  {name: "Ana Julia de Oliveira Silva", cpf: "462.065.410-05"},
  {name: "Ana Julia de Simone Brito dos San...", cpf: "503.710.400-01"},
  {name: "Ana Julia Faganello Dressano", cpf: "413.349.450-15"},
  {name: "Ana Julia Folegotto Fuzatto", cpf: "317.455.445-13"},
  {name: "Ana Julia Maielli Pupin", cpf: "431.964.705-02"},
  {name: "Ana Julia Mantuan Rodrigues", cpf: "537.713.450-15"},
  {name: "Ana Julia Marques de Abreu", cpf: "511.970.530-34"},
  {name: "Ana Julia Martins de Souza", cpf: "039.082.430-09"},
  {name: "Ana Julia Martins Galdino", cpf: "511.941.225-43"},
  {name: "Ana Julia Natucci Mergulhao", cpf: "466.033.401-02"},
  {name: "Ana Julia Oliveira Rodrigues", cpf: "142.867.100-05"},
  {name: "Ana Julia Reis de Andrade", cpf: "090.059.230-44"},
  {name: "Ana Julia Rocha Maciel", cpf: "458.938.220-40"},
  {name: "Ana Julia Silveira Costa", cpf: "160.996.100-04"},
  {name: "Ana Julia Sperandio Marchi", cpf: "421.161.795-55"},
  {name: "Ana Julia Storti Rosendo", cpf: "450.649.145-14"},
  {name: "Ana Julia Tanaka Vlach", cpf: "356.269.460-18"},
  {name: "Ana Julia Xavier Scopel", cpf: "434.396.110-08"},
  {name: "Ana Karolina Pereira Bittencourt", cpf: "485.470.145-15"},
  {name: "Ana Lara de Nobrega Marum", cpf: "424.973.255-59"},
  {name: "Ana Lara Ferreira", cpf: "470.231.195-31"},
  {name: "Ana Larissa Porto Leite", cpf: "481.225.255-59"},
  {name: "Ana Laura Altieri Cataneo", cpf: "419.813.540-37"},
  {name: "Ana Laura Chaves Ferreira", cpf: "464.211.415-06"},
  {name: "Ana Laura Cioffi Alves", cpf: "528.641.465-19"},
  {name: "Ana Laura de Freitas Tezza", cpf: "492.742.515-30"},
  {name: "Ana Laura Fernandes e Ferreira", cpf: "112.612.715-08"},
  {name: "Ana Laura Garcia Ribeiro de Olivei...", cpf: "517.815.485-23"},
  {name: "Ana Laura Marques Xavier", cpf: "515.507.540-37"},
  {name: "Ana Laura Mendes da Luz", cpf: "497.877.530-34"},
  {name: "Ana Laura Moya Georges", cpf: "445.127.775-27"},
  {name: "Ana Laura Pereira Magalhaes", cpf: "420.865.255-58"},
  {name: "Ana Laura Pereira Oliveira", cpf: "466.194.450-15"},
  {name: "Ana Laura Ramazotti Martins", cpf: "483.844.535-35"},
  {name: "Ana Laura Rasera Piai", cpf: "528.639.225-42"},
  {name: "Ana Laura Rocia de Souza", cpf: "419.675.775-27"},
  {name: "Ana Laura Saque", cpf: "475.036.480-22"},
  {name: "Ana Laura Tenorio Inacio", cpf: "402.619.215-38"},
  {name: "Ana Leticia Riffo Wechsler", cpf: "498.470.230-44"},
  {name: "Ana Ligia Perin Fregonese", cpf: "047.855.735-12"},
  {name: "Ana Livia da Silva Marcilio", cpf: "373.265.185-28"},
  {name: "Ana Livia Dias Carneiro Heluy", cpf: "057.311.565-42"},
  {name: "Ana Livia Ruegger Saldanha", cpf: "415.236.710-07"},
  {name: "Ana Liz Antunes Goncalves", cpf: "510.107.160-19"},
  {name: "Ana Lucia Cato", cpf: "511.826.771-24"},
  {name: "Ana Luisa Azanha Moda", cpf: "469.359.101-06"},
  {name: "Ana Luisa Carvalho Correa", cpf: "442.511.505-28"},
  {name: "Ana Luisa da Cunha Almeida", cpf: "498.544.505-28"},
  {name: "Ana Luisa Fracaro de Almeida", cpf: "509.986.780-28"},
  {name: "Ana Luisa Lot Divarzak", cpf: "506.152.400-01"},
  {name: "Ana Luisa Matheus Souza", cpf: "519.242.840-65"},
  {name: "Ana Luisa Santana", cpf: "435.686.445-13"},
  {name: "Ana Luisa Sayuri Tanaka Gibson", cpf: "493.187.265-62"},
  {name: "Ana Luisa Schuindt Fragozo", cpf: "103.522.230-44"},
  {name: "Ana Luisa Siqueira Machado", cpf: "482.245.230-44"},
  {name: "Ana Luiza Albuquerque Martins Ba...", cpf: "503.338.160-18"},
  {name: "Ana Luiza Almeida Barth Pires", cpf: "404.406.495-25"},
  {name: "Ana Luiza Assis Coutinho", cpf: "489.692.771-23"},
  {name: "Ana Luiza Badialle Costa", cpf: "510.632.145-14"},
  {name: "Ana Luiza Bellotti Whitaker", cpf: "528.751.255-59"},
  {name: "Ana Luiza Borges da Silva", cpf: "115.431.230-44"},
  {name: "Ana Luiza Carriel Griffo", cpf: "458.345.515-30"},
  {name: "Ana Luiza Cavallari de Oliveira", cpf: "528.871.840-65"},
  {name: "Ana Luiza de Castro Pereira de Bri...", cpf: "393.918.855-68"},
  {name: "Ana Luiza de Faria Silverio", cpf: "131.487.460-17"},
  {name: "Ana Luiza Dezani Cocolo", cpf: "495.980.260-60"},
  {name: "Ana Luiza Evangelista Galvane", cpf: "239.555.160-19"},
  {name: "Ana Luiza Franchito Cypriano", cpf: "359.966.555-40"},
  {name: "Ana Luiza Godoy Lopes", cpf: "529.436.215-39"},
  {name: "Ana Luiza Goncalves dos Santos", cpf: "708.521.230-44"},
  {name: "Ana Luiza Moreira Basso", cpf: "515.321.771-24"},
  {name: "Ana Luiza Pimenta Dutra", cpf: "147.298.145-14"},
  {name: "Ana Luiza Prado da Silva", cpf: "462.075.110-08"},
  {name: "Ana Luiza Sieto Sticke", cpf: "453.447.545-38"},
  {name: "Ana Luiza Soares do Canto", cpf: "468.493.165-23"},
  {name: "Ana Luiza Soares Mineiro Rocha", cpf: "031.236.775-27"},
  {name: "Ana Maria da Silva", cpf: "100.120.495-25"},
  {name: "Ana Maria Linhares Cavalcanti", cpf: "327.841.515-30"},
  {name: "Ana Maria Luisa Rodrigues Gomes", cpf: "368.568.230-44"},
  {name: "Ana Maria Oliveira Moura Salgado ...", cpf: "054.796.230-44"},
  {name: "Ana Maria Soares dos Santos Per...", cpf: "412.447.760-18"},
  {name: "Ana Paula", cpf: "413.955.485-23"},
  {name: "Ana Paula Andrade Santos", cpf: "459.593.510-29"},
  {name: "Ana Paula Clemente da Silva", cpf: "240.613.225-43"},
  {name: "Ana Paula de Oliveira Barros", cpf: "358.886.255-59"},
  {name: "Ana Paula Lima Silva", cpf: "227.744.255-59"},
  {name: "Ana Paula Lopes Silva", cpf: "445.780.445-14"},
  {name: "Ana Paula Veiga de Oliveira", cpf: "084.509.500-26"},
  {name: "Ana Paula Yuki Suguimoto", cpf: "514.827.790-53"},
  {name: "Ana Ramalho Frison", cpf: "470.575.215-38"},
  {name: "Ana Rita Ramos Jacundino", cpf: "525.526.510-29"},
  {name: "Ana Santos Oliveira", cpf: "433.162.780-32"},
  {name: "Ana Tamires dos Santos Souza", cpf: "483.851.100-02"},
  {name: "Ana Teresa Meyer Goncales", cpf: "484.958.450-15"},
  {name: "Ana Torres Barbosa", cpf: "382.879.215-38"},
  {name: "Ana Vitoria de Almeida Queiroz", cpf: "493.879.850-66"},
  {name: "Ana Vitoria Hortences da Silva de ...", cpf: "481.781.510-29"},
  {name: "Ana Yuki Kurimoto Trigilio", cpf: "461.226.145-15"},
  {name: "Anai Mendes Guimaraes Marcond...", cpf: "425.253.230-44"},
  {name: "Ananda Radhika Meron Postiglion...", cpf: "389.085.230-45"},
  {name: "Ananda Silva de Almeida", cpf: "369.229.230-44"},
  {name: "Anatasha Allison Li", cpf: "344.699.215-39"},
  {name: "Anderson Andrade Fernandes", cpf: "309.074.230-45"},
  {name: "Anderson Basoni Guimaraes Junio...", cpf: "169.693.780-29"},
  {name: "Anderson Brigido Costa", cpf: "313.407.550-39"},
  {name: "Anderson Gomes dos Santos", cpf: "415.162.450-15"},
  {name: "Anderson Gomes Rosa", cpf: "420.051.195-32"},
  {name: "Anderson Lins de Lima", cpf: "400.059.230-45"},
  {name: "Anderson Luiz Felix de Sa", cpf: "263.795.215-39"},
  {name: "Anderson Pereira da Silva", cpf: "510.417.150-16"},
  {name: "Anderson Santos Francelino", cpf: "470.488.870-70"},
  {name: "Andre Alencastro Curado Filho", cpf: "037.101.190-29"},
  {name: "Andre Aparecido Rocha Buccieri", cpf: "375.547.235-46"},
  {name: "Andre Augusto Braga Hoffmann", cpf: "432.958.160-20"},
  {name: "Andre Augusto Ferreira dos Santo...", cpf: "468.205.165-26"},
  {name: "Andre Banvolgyi", cpf: "445.531.190-29"},
  {name: "Andre Baranowski Tieri", cpf: "422.685.145-15"},
  {name: "Andre Calla", cpf: "522.567.401-03"},
  {name: "Andre Chui de Menezes", cpf: "177.876.255-59"},
  {name: "Andre Cordeiro Andrade", cpf: "483.860.240-48"},
  {name: "Andre Correia Tossani", cpf: "154.280.450-15"},
  {name: "Andre D’Affonseca Leitao Camarer...", cpf: "337.391.195-32"},
  {name: "Andre Daniel Pedroso Souza", cpf: "481.806.770-22"},
  {name: "Andre de Castro Amadio", cpf: "420.905.745-15"},
  {name: "Andre de Magalhaes Calsavara", cpf: "488.781.165-25"},
  {name: "Andre de Oliveira Sobral", cpf: "182.994.780-35"},
  {name: "Andre Domingues Peron", cpf: "462.878.780-34"},
  {name: "Andre dos Santos Sircilli", cpf: "325.644.100-01"},
  {name: "Andre Eidi Maeda", cpf: "476.037.780-36"},
  {name: "Andre Felipe da Silva Paz", cpf: "522.711.780-29"},
  {name: "Andre Ferreira Lopes", cpf: "360.205.165-21"},
  {name: "Andre Fraga Piovacari", cpf: "464.264.165-22"},
  {name: "Andre Franco Duarte", cpf: "173.884.771-23"},
  {name: "Andre Freire Bizzo", cpf: "514.888.165-25"},
  {name: "Andre Freitas Amaral", cpf: "509.626.145-14"},
  {name: "Andre Gilberto da Silva Filho", cpf: "505.847.790-41"},
  {name: "Andre Guilherme Carreira Tobias ...", cpf: "515.451.460-17"},
  {name: "Andre Kenji Hidaka Matsumoto", cpf: "526.303.710-07"},
  {name: "Andre Kenji Sonoda", cpf: "358.923.160-19"},
  {name: "Andre Kiyoshi Miyahara", cpf: "478.709.501-27"},
  {name: "Andre Ladeira Kesselring", cpf: "513.123.780-37"},
  {name: "Andre Lirio Nunes Santos", cpf: "414.002.795-55"},
  {name: "Andre Lopes Damasceno", cpf: "227.820.160-20"},
  {name: "Andre Luis Conrado Alberti", cpf: "423.978.235-46"},
]

export default all_people;
