import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import './Menu.css';

interface PageToolbarProps {
  title: string;
  back?: boolean;
}

const PageToolbar: React.FC<PageToolbarProps> = ({ title, back }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {back
            ? <IonBackButton defaultHref="/" />
            : <IonMenuButton />
          }
        </IonButtons>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  )
}

export default PageToolbar;