import { IonCard, IonCardContent, IonContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import PageToolbar from '../components/PageToolbar';

const NotFoundPage: React.FC<RouteComponentProps> = ({ match }) => {

  return (
    <IonPage>
      <IonContent fullscreen>
        <PageToolbar title="Not found" />

        <IonCard color="danger">
          <IonCardContent>
            The page <i>{match.url}</i> was not found
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
