import { IonAvatar, IonContent, IonItem, IonLabel, IonList, IonPage } from '@ionic/react';
import slugify from 'slugify';
import PageToolbar from '../components/PageToolbar';
import all_people from '../components/PeopleDB';

const ContactListPage: React.FC = () => {
  return (
    <IonPage>
      <PageToolbar title="Contacts" />

      <IonContent fullscreen>
        <IonList>
          {all_people.map((contact, index) => {
            return (
              <IonItem routerLink={`/contacts/${index}-${slugify(contact.name)}`} key={contact.cpf}>
                <IonAvatar slot="start">
                  <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200" />
                </IonAvatar>
                <IonLabel>{contact.name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ContactListPage;
