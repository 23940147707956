import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Route, RouteComponentProps } from 'react-router';
import ContactListPage from './ContactListPage';
import ContactDetailsPage from './ContactDetailsPage';

const ContactsPage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonPage id="contacts">
      <IonRouterOutlet>
        <Route exact path={match.url} component={ContactListPage} />
        <Route path={`${match.url}/:user-:slug`} component={ContactDetailsPage} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ContactsPage;
