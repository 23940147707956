import {
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { person, people, calendar, fastFood, mail, bonfire, chatbubbles, personCircle } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Login',
    url: '/login',
    icon: personCircle
  },
  {
    title: 'Meus dados',
    url: '/profile',
    icon: personCircle
  },
  {
    title: 'Contatos',
    url: '/contacts',
    icon: person
  },
  {
    title: 'Grupos',
    url: '/groups',
    icon: people
  },
  {
    title: 'Eventos',
    url: '/events',
    icon: calendar
  },

  {
    title: 'Delivery',
    url: '/delivery',
    icon: fastFood
  },
  {
    title: 'Entregas',
    url: '/mail',
    icon: mail
  },
  {
    title: 'Áreas Comuns',
    url: '/areas',
    icon: bonfire
  },
  {
    title: 'Conversar com a Administração',
    url: '/contact-admin',
    icon: chatbubbles
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>

        <IonHeader>
          <IonImg src="/assets/logo/logo-blue.svg" alt="Pass2Go" />
        </IonHeader>

        <IonList id="inbox-list">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;